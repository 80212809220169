<template>
    <transition name="fade">
        <div class="notify-modal" :class="classObj" v-if="params">
            <div class="notify-modal_icon" >
                <b-icon scale="1.5" :icon="params.errors ? 'x' : 'check'"></b-icon>
            </div>
            <h3  class="notify-modal_title" v-html="$content(params.key)"></h3>
            <div v-if="params.errors">
                <p>{{ params.errors }}</p>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Notify",
    computed: {
        params() {
            return this.$store.getters.activeModalParams
        },
        classObj() {
            let obj = {};
            obj[`notify-modal_${this.params.type}`] = true;

            return obj
        }
    }
}
</script>

<style lang="scss" scoped>
    .notify-modal {
        background: white;
        padding: 40px 60px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;

        & h3 {
            font-family: Montserrat-Medium, sans-serif;
            font-size: 18px;
            margin: 20px 0 15px 0;
        }

        &_success {

            .notify-modal_icon {
                background: var(--main-app);
            }

            & h3 {
                color: var(--main-app);
            }
        }

        &_fail {

            .notify-modal_icon {
                background: red;
            }

            & h3 {
                color: red;
            }

            & p {
                color: red;
                font-family: Montserrat-Light, sans-serif;
                font-weight: bold;
            }
        }

        &_title {
            margin-bottom: 0;
        }

        &_icon {
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 200px;
            flex-shrink: 0;

            & svg {
                color: white;
            }
        }
    }
</style>